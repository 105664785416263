<div class="container2">
  <div class="row main-display">    
    <div *ngIf="layoutData"  class="{{ layoutData.left }} poster">
      <div class="row">
        <div class="col-lg-12">
          <mdb-carousel #carousel *ngIf="carouselData" (slideChange)="onSlideChange()" [controls]="true" [indicators]="false" [animation]="'fade'">
            <mdb-carousel-item *ngFor="let carousel of carouselData; let index = index" id="slide{{index}}" (show)="onSlideShow()" [interval]="carousel.duration">
              <video *ngIf="carousel.type=='video';else image_content" id="slide_element{{index}}" (ended)="vidEnded()" (started)="vidStarted()"  onloadedmetadata="this.muted = true" controls id="vid" width="100%" muted="muted">
                <source [src]="carousel.source" type="video/mp4">
              </video>              
              <ng-template #image_content>
                <img [src]="carousel.source" class="d-block w-100" id="slide_element{{index}}"/>
                <div class="carousel-caption d-none d-md-block">
                  <h5>{{carousel.title}}</h5>
                  <p>{{carousel.description}}s</p>
                </div>
              </ng-template>
            </mdb-carousel-item>
            
          </mdb-carousel>
        </div>
      </div>  
    </div>
    <div *ngIf="announcementData" class="{{ layoutData.right }} announcement">
      <div class="row">
        <div class="col-lg-12">                              
            <mdb-carousel id="announcement_carousel" #announce *ngIf="announcementData"  (slideChange)="onAnnouncementSlideChange()" [controls]="true" [indicators]="false" [animation]="'fade'">
              <mdb-carousel-item  *ngFor="let announcement of announcementData; let index = index" id="slide_001" [interval]="announcement.duration">                
                  <iframe  *ngIf="announcement.type=='pdf';else pic_content" style="width: {{announcement.width}};height: {{announcement.height}}" [src]="announcement.source|safe"></iframe>
                  <ng-template #pic_content>
                    <video class="d-block w-100" *ngIf="announcement.type=='video';else image_content_" id="slide_element_001" (ended)="vidEnded()" (started)="vidStarted()"  onloadedmetadata="this.muted = true" autoplay loop playsinline id="vid" width="100%" muted="muted">
                    <source [src]="announcement.source" type="video/mp4" />
                  </video>
                 
                </ng-template>
                <ng-template #image_content_>
                  <img [src]="announcement.source" class="d-block w-100" id="slide_element_001"/>
                  <div class="carousel-caption d-none d-md-block">
                    <h5>{{announcement.title}}</h5>
                    <p>{{announcement.description}}</p>
                  </div>
                </ng-template>
              </mdb-carousel-item>
              
            </mdb-carousel>
            <div class="card {{announcementText.bgcolor}} text-white">          
              <div class="card-body">
                <div class="card-title">
                  <h4>{{announcementText.title}}</h4> 
                </div>
                <div class="card-text">
                  <h3>{{announcementText.subtitle}}</h3>
                  <h4>{{announcementText.description}}</h4>
                </div>
              </div>
            </div> 
          
          
        </div>
      </div>
    </div>

  </div>
  <!-- <div class="row separator">
    <div class="col-lg-12">
      <hr/>
    </div>
  </div> -->
  <div class="row agenda  ">
    <div *ngFor="let banner of bannerData; let index = index" class="{{banner.col}}">
      <img *ngIf="banner.type=='image';else text_content"  [src]="banner.source" [alt]="banner.title" class="banner img-fluid"/>
      <ng-template #text_content>
        <div class="card {{ banner.bgcolor }} text-white">          
          <div class="card-body">
            <div class="card-title">
              <h4>{{ banner.title }}</h4>
            </div>
            <div class="card-text">
              <h3>{{ banner.subtitle }}</h3>
              <h4>{{ banner.description }}</h4>
            </div>
          </div>
        </div>                
      </ng-template>
    </div>
    
  </div>
  <div class="row header bg-dark text-white bg-dark-transparent">  
    <div class="col-lg-12">
      <div class="row">        
        <div class="col-lg-2">
          <img class="logo img-fluid" style="height: 125px;" src="assets/Logo.png"/>
        </div>
        <div class="col-lg-8">
          <h1 class="display-3 d-flex justify-content-center align-items-center">Masjid Jami' Nurul Islam</h1>
          <h3 class="d-flex justify-content-center align-items-center">Jl. Bangka I No.1 Kel. Pela Mampang Kec. Mampang Prapatan Jakarta Selatan</h3>
        </div>
        <div class="col-lg-2">
          <h3 class="d-flex justify-content-center align-items-center">{{ dateOnly }}</h3> 
          <h1 class="display-3 d-flex justify-content-center align-items-center">{{ timenow }}</h1>
        </div>
      </div>      
      
    </div>
  </div>
  <div class="row jadwal-sholat">
    <div class="col">
      <div class="card">
        <div class="card-body {{ bgSubuh }}">
          <h4 class="card-title">Subuh{{ bgSubuh=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ subuh }}</h5>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2">
      <div class="card">
        <div class="card-body {{ bgTerbit }}">
          <h4 class="card-title">Terbit{{ bgTerbit=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ terbit }}</h5>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2">
      <div class="card">
        <div class="card-body {{ bgDzuhur }}">
          <h4 class="card-title">Dzuhur{{ bgDzuhur=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ dzuhur }}</h5>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2">
      <div class="card">
        <div class="card-body {{ bgAshr }}">
          <h4 class="card-title">Ashr{{ bgAshr=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ ashr }}</h5>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2">
      <div class="card">
        <div class="card-body {{ bgMaghrib }}">
          <h4 class="card-title">Maghrib{{ bgMaghrib=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ maghrib }}</h5>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2">
      <div class="card">
        <div class="card-body {{ bgIsya }}">
          <h4 class="card-title">Isya{{ bgIsya=='bg-info text-white' ? timeToGo : '' }}</h4>
          <h5 class="card-text">{{ isya }}</h5>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row separator">
    <div class="col-lg-12">
      <hr/>
    </div>
  </div> -->

</div>