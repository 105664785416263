import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { withCache } from '@ngneat/cashew';


@Injectable({
  providedIn: 'root',
})
export class HttpService {

  apiUrl = 'https://api.myquran.com/v2/sholat/jadwal/1301/2022/03/08';
  constructor(private httpClient: HttpClient) {}
  sendGetRequest(): Observable<any> {
    let dateNow = moment();
    this.apiUrl = 'https://api.myquran.com/v2/sholat/jadwal/1301/'+dateNow.locale('id').format('YYYY')+'/'+dateNow.locale('id').format('MM')+'/'+dateNow.locale('id').format('DD');
    let id = dateNow.locale('id').format('YYYY')+dateNow.locale('id').format('MM')+'/'+dateNow.locale('id').format('DD');
    return this.httpClient.get(this.apiUrl);
  }
  getCarousel(): Observable<any> {
    let dateNow = moment();    
    return this.httpClient.get('assets/carousel.json');
  }
}
