import { Component, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { finalize, map, share } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpService } from './http.service';
import { MdbCarouselComponent } from 'mdb-angular-ui-kit/carousel';
import { element } from 'protractor';

@Component({ 
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  {
  quote: string | undefined;
  subuh: string | undefined;
  terbit: string | undefined;
  dzuhur: string | undefined;
  ashr: string | undefined;
  maghrib: string | undefined;
  isya: string | undefined;
  timenow: string | undefined; 
  datetimenow: string | undefined;
  dateOnly: string | undefined;
  timeToGo: string | undefined;
  bgSubuh: string | '';
  bgTerbit: string | '';
  bgDzuhur: string | '';
  bgAshr: string | '';
  bgMaghrib: string | '';
  bgIsya: string | '';
  isLoading = false;
  subscription: Subscription;
  carouselData: [] | any;
  bannerData:object[];
  bannerDataAlt:object[];
  announcementData: [] | [];
  announcementText: {};
  layoutData:  {
    "left": "col-lg-7",
    "right": "col-lg-5",
  };
  jsonFn;
  initial = true;
  @ViewChild('carousel') carousel!: MdbCarouselComponent;
  constructor(private httpService: HttpService) {
  }
  
  ngOnInit() {
    let dateObj = new Date();
    this.isLoading = true;
    function resetOtherBg(except){
      
    }
    
    this.httpService.sendGetRequest().subscribe((responseBody) => {
      this.subuh = responseBody.data.jadwal.subuh;
      this.terbit = responseBody.data.jadwal.terbit;
      this.dzuhur = responseBody.data.jadwal.dzuhur;
      this.ashr = responseBody.data.jadwal.ashar;
      this.maghrib = responseBody.data.jadwal.maghrib;
      this.isya = responseBody.data.jadwal.isya;
      // document.querySelectorAll('#vid')[0].play()
    });
    this.jsonFn = (responseBody) : void => {
      this.bannerData = [];
      this.carouselData = responseBody.carousel.data;
      // this.bannerData = responseBody.banner.data;
      this.announcementData = responseBody.announcement.data;
      responseBody.banner.data.forEach( (element:object,idx) => {        
        if(Array.isArray(element) && typeof(this.bannerData) === 'undefined'){
          element[0]['single'] = false;
          this.bannerData = [element[0]];
          this.bannerDataAlt = [element[1]]
        }else if(Array.isArray(element) && typeof(this.bannerData) !== 'undefined'){
          element[0]['single'] = false;
          this.bannerData.push(element[0]);
          if(typeof(this.bannerDataAlt)==='undefined'){
            this.bannerDataAlt = [element[1]];
          }else if (typeof(this.bannerDataAlt) !=='undefined'){
            this.bannerDataAlt.push(element[1]);
          }
        }else if(!Array.isArray(element) && typeof(this.bannerData) === 'undefined'){
          element['single'] = true;
          this.bannerData = [element];
        }else if(!Array.isArray(element) && typeof(this.bannerData) !== 'undefined'){
          element['single'] = true;
          this.bannerData.push(element);
        }
      });
      this.layoutData = responseBody.mainlayout;
      this.announcementText = responseBody.announcement_text;
    }
    this.httpService.getCarousel().subscribe((responseBody) => {     
      this.initial = false 
      this.jsonFn(responseBody)
    })
    this.subscription = timer(0, 10000)
      .subscribe(() => {
        if(typeof(this.bannerData)==='undefined'){
          return;
        }
        this.bannerData.forEach((element,idx) => {
          if(!element['single']){
            this.bannerDataAlt[1] =  this.bannerData[idx];
            this.bannerData[idx] = this.bannerDataAlt[0];
            this.bannerDataAlt[0] =   this.bannerDataAlt[1]           
          }
        });
      })
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(() => {
        let resHeight = window.screen.availHeight;
        let resWidth = window.screen.availWidth;
        this.datetimenow = moment().locale('id').format('D MMMM YYYY, HH:mm:ss') + '==>' + resWidth + ' x ' + resHeight;
        this.dateOnly = moment().locale('id').format('D MMMM YYYY');
        this.timenow = moment().locale('id').format('HH:mm:ss');
        const myMomentSubuh = moment(this.subuh, 'hh:mm')
        const myMomentTerbit = moment(this.terbit, 'hh:mm')
        const myMomentDzuhur = moment(this.dzuhur, 'hh:mm')
        const myMomentAshr = moment(this.ashr, 'hh:mm')
        const myMomentMaghrib = moment(this.maghrib, 'hh:mm')
        const myMomentIsya = moment(this.isya, 'hh:mm')
        if( moment() > myMomentSubuh && moment() <= myMomentTerbit ){
          let duration = moment.duration(myMomentTerbit.diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgSubuh = '';
          this.bgTerbit = 'bg-info text-white';
        }
        if( moment() > myMomentTerbit && moment() <= myMomentDzuhur ){
          let duration = moment.duration(myMomentDzuhur.diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgTerbit = '';
          this.bgDzuhur = 'bg-info text-white';
        }
        if( moment() > myMomentDzuhur && moment() <= myMomentAshr ){
          let duration = moment.duration(myMomentAshr.diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgDzuhur = '';
          this.bgAshr = 'bg-info text-white';
        }
        if( moment() > myMomentAshr && moment() <= myMomentMaghrib ){
          let duration = moment.duration(myMomentMaghrib.diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgAshr = '';
          this.bgMaghrib = 'bg-info text-white';
        }
        if( moment() > myMomentMaghrib && moment() <= myMomentIsya ){
          let duration = moment.duration(myMomentIsya.diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgMaghrib = '';
          this.bgIsya = 'bg-info text-white';
        }
        if( moment() > myMomentIsya || moment() < myMomentSubuh ){
          let duration = moment.duration(myMomentSubuh.add(1,'day').diff(moment()));
          this.timeToGo = ', '+duration.locale('id').humanize()+' lagi';
          this.bgIsya = '';
          this.bgSubuh = 'bg-info text-white';
        }       
      });

    this.subscription = timer(0, 1000 * 60 * 60)
    .subscribe(() => {
      console.log('Checking new Data')
      if(this.initial)
        return
      this.httpService.getCarousel().subscribe((responseBody) => {      
        this.jsonFn(responseBody)
      })
    })
  
     
  }
  ngAfterContentChecked() {
    // var targetNode = document.getElementById('slide1');
    // var observer = new MutationObserver(function(){
    //     if(targetNode.style.display != 'none'){
    //         console.log('show');
    //     }
    // });
    // // console.log(targetNode);
    // if(targetNode !== null){
    //   observer.observe(targetNode, { attributes: true, childList: true });
    // }
      //Copy in all the js code from the script.js. Typescript will complain but it works just fine
  }
  onSlideChange(): void {
    // console.log(this);
    if(this.carouselData[this.carousel.activeSlide].type == 'video'){
      const videoSlide = <HTMLVideoElement>document.getElementById('slide_element'+this.carousel.activeSlide);
      var isPlaying = videoSlide.currentTime > 0 && !videoSlide.paused && !videoSlide.ended && videoSlide.readyState > videoSlide.HAVE_CURRENT_DATA;
      if (!isPlaying) {
        videoSlide.play();
      }else{ 
        setTimeout(() => {
          videoSlide.play();
        }, 300);
      }      
    }
  }
  onAnnouncementSlideChange(): void {
    console.log(this);   
  }
  vidStarted():void{
    console.log('Video Started');
  }
  vidEnded():void{
    console.log('Video Ended');
    this.carousel.next();
  }
  onSlideShow():void{
    console.log(this);
    console.log('slide');    
  }
  
}
